<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
  @font-face {
        font-family: 'CaveatBrush';
        src: local('CaveatBrush'), url('./assets/fonts/CaveatBrush-Regular.ttf')  format('truetype');
      }
      @font-face {
        font-family: 'CaveatBold';
        src: local('CaveatBold'), url('./assets/fonts/Caveat-Bold.ttf')  format('truetype');
      }
      @font-face {
        font-family: 'CaveatReg';
        src: local('CaveatReg'), url('./assets/fonts/Caveat-Regular.ttf')  format('truetype');
      }
  </style>