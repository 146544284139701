<template>
  <v-app id="home" :style="{background: $vuetify.theme.themes.dark.background}">
    <v-container fluid>
      <v-row>
        <template class="container">
          <Navbar />
          <v-img src="landingpage.png" class="landingpage" aspect-ratio="2"></v-img>
        </template>
        
        <div class="aboutcontainer">
          <v-col cols="4" id="about" class="" style="
            text-align: center;
            width: 40%;
            margin: 0 auto;
            padding-top: 85px;
            padding-bottom: 85px;">
            <h2 class="primeheader red--text text-darken-4 top">Hi, welcome to my portfolio.</h2>
            <p class="black--text">
              I am a professional software developer (B.Sc.) with more than three years of experience in the industry.
              I specialize in web development, game development and UX/UI-Design. 
              I believe that creating accessible and inclusive software, that plays to peoples strengths and 
              respects their impairments, is as important as any other aspect in the development process.
            </p>
          </v-col>

          <v-col cols="12" class="aboutcontainer" style="
            text-align: center;
            width: 90%;
            margin: 0 auto;">
            <div class="">
              <h2 class="primeheader red--text text-darken-4" style=" padding-bottom: 25px;">
                Experience and education
              </h2>
              <v-row>
                    <v-card
                      class="mx-auto"
                      max-width="400"
                      max-height="400"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            May 2022 - Aug. 2022
                          </div>
                          <div>XDi - Institute</div>
                          <v-list-item-title class="text-h5 mb-1">
                            Certification UX - Designer
                          </v-list-item-title>
                          <v-divider></v-divider>
                          <v-spacer></v-spacer>
                          <div class="text--primary">Repeating and manifesting important UX principles and methods like: 
                            Research, analysis, testing, 
                            strategic and feature planning, 
                            information, organization and navigation architecture, 
                            interaction and information design and wireframing as well as 
                            visual design and prototyping
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                    <v-card
                      class="mx-auto"
                      max-width="400"
                      max-height="400"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            Apr. 2021 - Apr. 2022
                          </div>
                          <div>Vissio Servicegroup GmbH</div>
                          <v-list-item-title class="text-h5 mb-1">
                            UX/UI-Designer & FullStack
                          </v-list-item-title>
                          <v-divider></v-divider>
                          <v-spacer></v-spacer>
                          <div class="text--primary">I was hired as a Front-End-Developer but my responsibilities quickly changed to 
                            FullStack-Development and UX-Design. I held interviews with stakeholders & users, 
                            created information architectures & wireframes, which I tested for their usability. 
                            I created prototypes and screen designs which I ultimately developed 
                            into real applications and features.
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                    <v-card
                      class="mx-auto"
                      max-width="400"
                      max-height="400"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            Jan. 2019 - Oct. 2022
                          </div>
                          <div>NK Mamoni Gasto GmbH</div>
                          <v-list-item-title class="text-h5 mb-1">
                            Restaurant Owner
                          </v-list-item-title>
                          <v-divider></v-divider>
                          <v-spacer></v-spacer>
                          <div class="text--primary">Management of employees and logistics, operation of day to day business, accounting, customer relations, creating and managing web-presence and social media</div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                    <v-card
                      class="mx-auto"
                      max-width="400"
                      max-height="400"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            Oct. 2016 - Jul. 2021
                          </div>
                          <div>HTW Berlin</div>
                          <v-list-item-title class="text-h5 mb-1">
                            B.Sc. CompSci and Media
                          </v-list-item-title>
                          <v-divider></v-divider>
                          <v-spacer></v-spacer>
                          <div class="text--primary">Specialization: Usability Design, Web- and Game Development</div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
              </v-row>
            </div>
          </v-col>
        </div>

        <div class="projectscontainer second" id="projects">
            <h2 class="primeheader red--text text-darken-4 top" style=" padding-bottom: 25px;">Projects</h2>
            <v-col cols="12" style="
            text-align: center;
            width: 95%;
            margin: 0 auto;
            padding-bottom: 85px;">
              <v-row>
                  <v-card
                    elevation="24"
                    class="mx-auto"
                    max-width="850"
                  >
                    <v-carousel
                      :continuous="true"
                      :cycle="cycle"
                      :show-arrows="true"
                      hide-delimiter-background
                      delimiter-icon="mdi-minus"
                      height="500"
                    >
                      <v-carousel-item
                      v-for="(item,i) in items"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      >
                      
                      </v-carousel-item>
                    </v-carousel>
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h5 mb-1">City Tourism Site</v-list-item-title>
                          <v-list-item-subtitle>Design in Figma</v-list-item-subtitle>
                          <v-spacer></v-spacer>
                          <div class="text--primary">The assignment was to create a web presence for a city and to establish it as a tourist attraction. 
                            The scope of the assignment covered everything necessary in UX design: creating questionnaires and holding interviews with users, 
                            creating empathy maps and personas, customer life cycles, experience maps, use cases, user scenarios, user stories, 
                            product analyses, marketing strategies, feature and content planning, stakeholder management, information architecture, 
                            sitemaps, organization structure, card sorting, navigation, search and filters, processes and flows, scribbles, 
                            wireframes and prototypes, interaction design, design patterns, mobile and responsive design.  </div>
                        </v-list-item-content>
                        <v-list-item-action>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                  <v-card
                    elevation="24"
                    class="mx-auto"
                    max-width="850"
                  >
                    <v-carousel
                      :continuous="true"
                      :cycle="cycle"
                      :show-arrows="true"
                      hide-delimiter-background
                      delimiter-icon="mdi-minus"
                      height="500"
                    >
                      <v-carousel-item
                      v-for="(item,i) in items2"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      >
                      
                      </v-carousel-item>
                    </v-carousel>
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h5 mb-1">Search and navigation</v-list-item-title>
                          <v-list-item-subtitle>Design in AdobeXD. Development in Vue.js, PHP, MariaDB</v-list-item-subtitle>
                          <v-spacer></v-spacer>
                          <div class="text--primary">I redesigned the search and navigation features of Vissio Servicegroup GmbHs. 
                            Their website is used daily by their employees. It's essential to the workflow. 
                            Users ranged in a variety of ways from different age to technical proficiency and different levels of impairments. 
                            The challenge here was to design a system that would support that variety. 
                            The stakeholders left me with creative freedom the only conditions were to optimize the information 
                            architecture and to keep all previous function. In order to optimize the IA I held card sorting workshops. 
                            Based on the information I gathered I redesigned the IA and developed wireframes. 
                            After some feedback phases we settled on the final wireframe. I used those to design the screen. 
                            Further feedback phases followed until we settled on the final design.</div>
                        </v-list-item-content>
                        <v-list-item-action>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                  <v-card
                    elevation="24"
                    class="mx-auto"
                    max-width="850"
                  >
                    <v-carousel
                      :continuous="true"
                      :cycle="cycle"
                      :show-arrows="true"
                      hide-delimiter-background
                      delimiter-icon="mdi-minus"
                      height="500"
                    >
                      <v-carousel-item
                      v-for="(item,i) in items3"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      >
                      
                      </v-carousel-item>
                    </v-carousel>
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h5 mb-1">Calendar & Taskmanager (c&t)</v-list-item-title>
                          <v-list-item-subtitle>Design in AdobeXD. Development in Vue.js, PHP, MariaDB</v-list-item-subtitle>
                          <v-spacer></v-spacer>
                          <div class="text--primary">I was responsible for the redesigned of the c&t feature of Vissios 
                            website. The situation and challenges here were the same as the ones with the search and navigation. 
                            The stakeholders wanted a 1:1 recreation of the existing c&t but matching the look and feel of the new website. 
                            After holding interviews with users I learned that the c&t is one if not the most essential feature of the 
                            website. I presented my findings to the stakeholders and prepared potential solution-wireframes. After agreeing on 
                            one I continued the design process until we ended up with two beautiful new features. Instead of being its own page 
                            the calendar became a more user friendly, accessible, responsive and efficient feature that would be accessible 
                            anywhere on the website and users didn't need to open new tabs or change sites (which was a big pain for the old 
                            design). Further the interviews revealed that users often used the c&t the check when they logged into the 
                            system in order to calculate how long they'd have to be working for that day. This became it's own feature where 
                            users could conveniently check their worktime.</div>
                        </v-list-item-content>
                        <v-list-item-action>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                  <v-card
                    elevation="24"
                    class="mx-auto"
                    max-width="850"
                  >
                    <v-carousel
                      :continuous="true"
                      :cycle="cycle"
                      :show-arrows="false"
                      hide-delimiter-background
                      delimiter-icon="mdi-minus"
                      height="500"
                    >
                      <v-carousel-item
                      v-for="(item,i) in items4"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      >
                      
                      </v-carousel-item>
                    </v-carousel>
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h5 mb-1">Skribbl.AI</v-list-item-title>
                          <v-list-item-subtitle>Design in Canva and Sketch. Development in HTLM, JS, CSS</v-list-item-subtitle>
                          <v-spacer></v-spacer>
                          <div class="text--primary">
                            The assignment was to design a game for a small dev team.
                            The goal was to create a very simple and efficient UI to reduce loading times. 
                            It was important to create an easy to understand and accessible usability experience 
                            since the game was meant to be played by lots of different people. 
                            We started with a variety of different paper prototypes testing them with people and changing them appropriately. 
                            I then moved to gameplay design, wich I regulary tested and adjusted in different testing sessions. 
                            After several design workshops and meetings we found the perfect UI/UX solution for efficiency and usability.
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
              </v-row>
            </v-col>
          </div>
      </v-row>
      <div class="contactcontainer" id="contact">
          <v-col cols="4" class="" style="
            text-align: center;
            width: 40%;
            margin: 0 auto;
            padding-top: 85px;
            padding-bottom: 85px;">
            <h2 class="primeheader red--text text-darken-4 top">Let's work together on your next project!</h2>
            <div>Here is how you can get in touch:</div>
            <v-btn
              class="mx-4 black--text"
              icon
              :href="'mailto:' + 'ninoslav@kjireski.com'"
            >
              <v-icon size="24px">
                {{ 'mdi-email' }}
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-4 black--text"
              icon
              href="https://www.linkedin.com/in/ninoslav-kjireski-879bb217b/" target="_blank"
            >
              <v-icon size="24px">
                {{ 'mdi-linkedin' }}
              </v-icon>
            </v-btn>
            <div>You can check out and download my CV here:</div>
            <v-btn
              class="mx-4 black--text"
              icon
              href="https://www.dropbox.com/s/afjl29oo5212csq/CV%20Ninoslav%20Kjireski%20%284%29.pdf?dl=0" target="_blank"
            >
              <v-icon size="24px">
                {{ "mdi-file-document" }}
              </v-icon>
            </v-btn>
            <p class="text-h6 mb-1">
              Feel free to ask if you have questions. <br> I'm looking forward to hearing from you.
            </p>
          </v-col>
        </div>
    </v-container>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

  export default {
    name: 'Home',

    components: {
    Navbar
    },
    data: () => ({
      model: 0,
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ],
      cycle: false,
        items: [
          {
            src: 'smartberg1.png',
          },
          {
            src: 'smartberg2.png',
          },
          {
            src: 'smartberg3.png',
          },
          {
            src: 'smartberg4.png',
          },
          {
            src: 'smartberg5.jpg',
          },
        ],
        items2: [
          {
            src: 'search1.jpg',
          },
          {
            src: 'search2.jpg',
          },
          {
            src: 'search3.png',
          },
          {
            src: 'search4.png',
          },
          {
            src: 'search5.png',
          },
        ],
        items3: [
          {
            src: 'ukv1.jpg',
          },
          {
            src: 'ukv2.jpg',
          },
          {
            src: 'ukv3.jpg',
          },
          {
            src: 'ukv4.png',
          },
          {
            src: 'ukv5.png',
          },
          {
            src: 'ukv6.png',
          },
          {
            src: 'ukv7.png',
          },
        ],
        items4: [
          {
            src: 'skribbl.png',
          }
        ],
        icons: [
        'mdi-email',
        'mdi-linkedin',
      ],
    }),
  }
</script>

<style scoped>
.contactcontainer {
  padding-top: 100px;
}
.mx-auto {
  margin-bottom: 10px;
}
.primeheader {
    font-family: "CaveatBold", Helvetica, Arial;
     font-size: 40px;
  }
.v-application .text-h5 {
  font-weight: 500;
  }
#home > div > div > div > header {
  background-color: rgb(255 255 255 / 0%) !important;
  padding-top: 30px;
}
#home > div > div > div > header > div > div:nth-child(1) {
  left: -45px !important;
}
.container {
  display: inline-block;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: visible;
  z-index: 100;
}
.aboutcontainer, .contactcontainer {
  height: 100vh;
  width: 100vw;
}
.landingpage {
  width: 100%;
  top: -65px;
}
.top{
  margin-top: 100px;
}
.topInverse{
  margin-top: -250px;
}
.topTolbar{
  margin-top: 100px;
  text-align: center;
}
4.first {
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
}
.second {
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
}
.secondchild1 {
  display: inline-block;
  background-color: #1e1e1e;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: left;
  margin-top: 250px;
}
.child {
  display: inline-block;
  width: 400px;
  height: 420px;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: left;
  margin-right: 8px;
}
.bgColor1 {
  background-color: #1e1e1e;
}
.bgColor2 {
  background-color: #ce1d2a;
}
.child1{
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  margin-right: 5px;
  width: 240px;
}
.child2 {
  display: inline-block;
  width: 245px;
  vertical-align: middle;
}
.mRight {
  margin-right: 8px;
}
.mButton {
  margin-bottom: 8px;
}
.padding {
  padding: 8px 0px;
}
.col-12.padd {
  padding: 12px 0 !important;
}
.col-12.childcol {
  padding: 0 !important;
}
h1.number {
  font-size: 50px;
  font-weight: bold;
}
</style>
