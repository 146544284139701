<template>
  <v-app-bar app flat color="#FFFFFF" class="navbarFont">
    
    <v-btn class="navItem" text @click="scroll('home')">NINOSLAV KJIRESKI</v-btn>
    <v-spacer></v-spacer>
    <v-btn class="navItem" text @click="scroll('about')">About</v-btn>
    <v-btn class="navItem" text @click="scroll('projects')">Projects</v-btn>
    <v-btn class="navItem" text @click="scroll('contact')">Contact</v-btn>
  </v-app-bar>
</template>

<script>
export default {
    methods: {
        scroll(refName) {
            const element = document.getElementById(refName);
            element.scrollIntoView({behavior: "smooth"});
        }
    }
}
</script>

<style scoped>
.v-toolbar__title {
    font-size: 2.875vw;
}
/* #home > div > div > div > header > div > button:nth-child(3), #home > div > div > div > header > div > button:nth-child(4) {
    padding-right: 150px;
    } */
.v-btn.v-size--default {
    font-size: 2.875vw;
}
.navbarFont {
    font-family: "CaveatBrush", Helvetica, Arial;
     font-size: 20px;
     z-index: 101;
  }
</style>